import React, { Fragment } from "react";
import { Alert } from "react-bootstrap";
import RingLoader from "react-spinners/RingLoader";
import { baseUriApi } from "../../../services/const";
import { customFetch } from "../../../services/fetch";

export default function ContactForm(props) {
  const [state, setState] = React.useState({
    email: "",
    name: "",
    phone: "",
    message: props.defaultMessage ? props.defaultMessage : "",
  });
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isSendingMail, setIsSendingMail] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    if (!state.email || !state.name || !state.message) {
      if (!state.email) {
        setErrorMessage("Email vide");
      }
      if (!state.name) {
        setErrorMessage("Nom vide");
      }
      if (!state.message) {
        setErrorMessage("Message vide, merci d'en saisir un :)");
      }
    }
    setIsSendingMail(true);
    setSuccess(false);
    setErrorMessage("");

    customFetch("POST", `${baseUriApi}email/sendemail`, state)
      .then(() => {
        setIsSendingMail(false);
        setSuccess(true);
      })
      .catch((error) => {
        setIsSendingMail(false);
        setSuccess(false);
        setErrorMessage("Internal Server Error");
        console.error("There was an error!", error);
      });

    e.preventDefault();
  };

  const { returnLink, spinnerLoadingColor } = props;

  return (
    <Fragment>
      <h2>Me contacter</h2>
      <div className="d-flex" style={{ minHeight: "250px" }}>
        {success ? (
          <Alert variant="success" className="m-auto">
            Merci pour votre message, nous allons vous répondre sous peu à l'adresse mail saisie.
            {returnLink && (
              <p>
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    setSuccess(false);
                  }}
                >
                  Renvoyer un message
                </a>
              </p>
            )}
          </Alert>
        ) : isSendingMail ? (
          <div className="m-auto">
            <RingLoader
              css={"display:block;margin:auto;border-color: red"}
              size={80}
              color={spinnerLoadingColor ? spinnerLoadingColor : "#444"}
              loading={isSendingMail}
            />
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="pt-2">
                <input
                  className="form-control"
                  id="name-ecuriesduchalet"
                  name="name"
                  label="name"
                  placeholder="Nom prénom"
                  value={state.name}
                  onChange={handleChange}
                />
              </div>
              <div className="pt-2">
                <input
                  className="form-control"
                  id="email-ecuriesduchalet"
                  name="email"
                  label="email"
                  placeholder="Email"
                  value={state.email}
                  onChange={handleChange}
                />
              </div>
              <div className="pt-2">
                <input
                  className="form-control"
                  id="phone-ecuriesduchalet"
                  name="phone"
                  label="phone"
                  placeholder="Téléphone"
                  value={state.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="pt-2">
                <textarea
                  className="form-control"
                  style={{ minHeight: "100px" }}
                  name="message"
                  label="message"
                  value={state.message}
                  placeholder="Votre message"
                  onChange={handleChange}
                />
              </div>

              <button type="submit" className="mt-2 btn btn-primary">
                Envoyer
              </button>
            </form>

            {errorMessage ? (
              <Alert variant="danger" className="pt-2 m-auto">
                {
                  "Désolé ... Fonctionnalité pour l'instant indisponible, merci de me contacter par téléphone au 0620526418, Facebook, Instagram ou par mail : contact@lesecuriesduchalet.com "
                }
              </Alert>
            ) : null}
          </div>
        )}
      </div>
    </Fragment>
  );
}
