import React from 'react';
import * as styles from '../styles.module.scss'

export default class Items extends React.Component {
    render() {
        return (
            <ul className={styles.listGroup}>
                {
                    this.props.children.map((child) => {
                        return <li className={styles.listGroupItem}><i className="fa fa-times"></i>{child}</li>
                    })
                }
            </ul>
        )
    }
}