import React from "react";
import { Modal } from "react-bootstrap";
import ContactForm from "../components/site/ContactForm";
import PricePanel from "../components/site/PricePanel";
import { FaCheck, FaCircle } from "react-icons/fa";
import Layout from "../components/site/layout";
import SEO from "../components/site/seo";
import "../styles/prices.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { isBrowser } from "../services/helper";

export default class Prix extends React.Component {
  appearAnimation = [];
  constructor(props) {
    super(props);

    this.state = {
      modalShow: false,
    };
  }

  componentDidMount() {
    if (isBrowser()) {
      gsap.registerPlugin(ScrollTrigger);
    }
    this.appearAnimation.forEach((element) => {
      gsap.fromTo(
        element,
        { opacity: 0 },
        {
          opacity: 1,
          scrollTrigger: element,
          duration: 0.6,
          ease: "Circ.easeIn",
        }
      );
    });
  }

  render() {
    return (
      <Layout currentPage="prix">
        <SEO title="Tarifs" description=" Consulter les formules et tarifs proposés.
        Contactez moi si vous voulez plus de renseignements sur les tarifs ou autres questions.
        Page de tarifs du centre équestre des écuries du Cha'let (chalet) à Annecy Epagny en Haute Savoie, très prês de la suisse et genève.
        Pour me contacter n'hésitez pas à me joindre sur le numéro de téléphone indiqué en bas de page.
        Pension cheval accompagnée de bons aménagements et de box copeaux !
        Balades à cheval, poney, manège et carrière olympique 20x40 m, des installations de qualité. 
        Pensions pour chevaux accompagnée de bons aménagements et de box copeaux !
        Cours de poney et de chevaux. Faites découvrir cette passion à votre enfant. Cheval pour enfant (chevaux pour enfants)
        Compétitions, cours particuliers et collectifs, le poney club contient tout ce qu'il vous faut alors venir le visiter" />

        <Modal show={this.state.modalShow} onHide={() => this.setState({ modalShow: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Formulaire de contact</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ContactForm defaultMessage={this.state.modalMessage} />
          </Modal.Body>
        </Modal>

        <div className="container-fluid">
          <div>
            <h1
              ref={(div) => {
                this.appearAnimation.push(div);
              }}
            >
              Tarifs
            </h1>
          </div>
          <div id="prices" className="row justify-content-center mt">
            <div
              className="col-md-auto"
              ref={(div) => {
                this.appearAnimation.push(div);
              }}
            >
              <PricePanel>
                <PricePanel.Title>COURS PARTICULIERS</PricePanel.Title>
                <PricePanel.Items>
                  <>45 minutes de cours</>
                  <>Seul, à 2 ou à 3</>
                  <>Sur rendez-vous</>
                </PricePanel.Items>
                <PricePanel.FooterPricing
                  type="cours particulier"
                  showModal={(msg = "") => this.setState({ modalShow: true, modalMessage: msg })}
                  prices={[
                    { title: "Solo", price: "45€" },
                    { title: "De 2 à 3 personnes", price: "35€/pers" },
                  ]}
                ></PricePanel.FooterPricing>
              </PricePanel>
            </div>
            <div
              className="col-md-auto"
              ref={(div) => {
                this.appearAnimation.push(div);
              }}
            >
              <PricePanel>
                <PricePanel.Title>COURS COLLECTIFS BABY</PricePanel.Title>
                <PricePanel.Items>
                  <>De 3 à 5 ans</>
                  <>Mercredi de 9h à 10h et de 11h30 à 12h30</>
                  <>Samedi de 9h à 10h</>
                  <>Paiment différé si besoin</>
                </PricePanel.Items>
                <PricePanel.FooterPricing
                  type="cours collectifs baby du"
                  showModal={(msg = "") => this.setState({ modalShow: true, modalMessage: msg })}
                  prices={[
                    { title: "Adhésion + licence", price: "80€ à l'année" },
                    { title: "1er trimestre", subtitle: "13 cours", price: "234 €" },
                    { title: "Inscription annuelle", price: "612.5 €" },
                  ]}
                ></PricePanel.FooterPricing>
              </PricePanel>
            </div>
            <div
              className="col-md-auto"
              ref={(div) => {
                this.appearAnimation.push(div);
              }}
            >
              <PricePanel>
                <PricePanel.Title>COURS COLLECTIFS -18ans</PricePanel.Title>
                <PricePanel.Items>
                  <>De 6 à 18 ans (compris)</>
                  <>Lundi, mardi, jeudi, vendredi : de 17h à 20h</>
                  <>Mercredi et samedi : de 9h à 20h</>
                  <>Paiment différé si besoin</>
                </PricePanel.Items>
                <PricePanel.FooterPricing
                  type="cours collectifs -18ans du"
                  showModal={(msg = "") => this.setState({ modalShow: true, modalMessage: msg })}
                  prices={[
                    { title: "Adhésion + licence", price: "120€ à l'année" },
                    { title: "1er trimestre", subtitle: "1x / semaine", price: "273 €" },
                    { title: "1er trimestre", subtitle: "2x / semaine", price: "520 €" },
                    { title: "Inscription annuelle", subtitle: "1x / semaine", price: "717.5 €" },
                    { title: "Inscription annuelle", subtitle: "2x / semaine", price: "1365 €" },
                  ]}
                ></PricePanel.FooterPricing>
              </PricePanel>
            </div>
            <div
              className="col-md-auto"
              ref={(div) => {
                this.appearAnimation.push(div);
              }}
            >
              <PricePanel>
                <PricePanel.Title>COURS COLLECTIFS + 18ans</PricePanel.Title>
                <PricePanel.Items>
                  <>À partir de 19 ans</>
                  <>Lundi, mardi, jeudi, vendredi : de 17h à 20h</>
                  <>Mercredi et samedi : de 9h à 20h</>
                  <>Paiment différé si besoin</>
                </PricePanel.Items>
                <PricePanel.FooterPricing
                  type="cours collectifs +18 ans du"
                  showModal={(msg = "") => this.setState({ modalShow: true, modalMessage: msg })}
                  prices={[
                    { title: "Adhésion + licence", price: "130€ à l'année" },
                    { title: "1er trimestre", subtitle: "1x / semaine", price: "286 €" },
                    { title: "1er trimestre", subtitle: "2x / semaine", price: "546 €" },
                    { title: "Inscription annuelle", subtitle: "1x / semaine", price: "752.5 €" },
                    { title: "Inscription annuelle", subtitle: "2x / semaine", price: "1435 €" },
                  ]}
                ></PricePanel.FooterPricing>
              </PricePanel>
            </div>

            {/*
               <div
              className="col-md-auto"
              ref={(div) => {
                this.appearAnimation.push(div);
              }}
            >
              <PricePanel>
                <PricePanel.Title>CONCOURS</PricePanel.Title>
                <PricePanel.Items>
                  <>Une journée</>
                  <>2 passages</>
                  <>Trajets gratuits</>
                  <>Tarifs étudiants</>
                </PricePanel.Items>
                <PricePanel.Pricing>50€/concours</PricePanel.Pricing>
              </PricePanel>
            </div>
            */}

            <div
              className="col-md-auto"
              ref={(div) => {
                this.appearAnimation.push(div);
              }}
            >
              <PricePanel>
                <PricePanel.Title>PENSION COMPLETE</PricePanel.Title>
                <PricePanel.Items>
                  <>Grain compris</>
                  <>Box copeaux</>
                  <>Sortie au paddock / pré</>
                  <>Accès aux installations en dehors des horaires des cours</>
                  <>Prestation en plus sur demande (tonte, etc...)</>
                </PricePanel.Items>
                <PricePanel.Pricing>480 €/mois</PricePanel.Pricing>
                <PricePanel.Footer
                  type="pension complète"
                  showModal={(msg = "") => this.setState({ modalShow: true, modalMessage: msg })}
                ></PricePanel.Footer>
              </PricePanel>
            </div>
          </div>

          {/*
          <div className="mt">
            <h1
              ref={(div) => {
                this.appearAnimation.push(div);
              }}
            >
              {" "}
              Tarifs pensions
            </h1>
          </div>
          <div
            className="mt"
            ref={(div) => {
              this.appearAnimation.push(div);
            }}
          >
            <table className="table table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>Au pré</th>
                  <th>Pension de travail</th>
                  <th>En box</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Nombre de cours particuliers offert</td>
                  <td>0</td>
                  <td>3</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>Foin illimité</td>
                  <td>
                    <FaCheck />
                  </td>
                  <td>
                    <FaCheck />
                  </td>
                  <td>
                    <FaCheck />
                  </td>
                </tr>
                <tr>
                  <td>Jusqu'à 2L de grain</td>
                  <td>
                    <FaCircle />
                  </td>
                  <td>
                    <FaCheck />
                  </td>
                  <td>
                    <FaCheck />
                  </td>
                </tr>
                <tr>
                  <td>Prise travail jusqu'à 3 fois par semaines</td>
                  <td>
                    <FaCircle />
                  </td>
                  <td>
                    <FaCheck />
                  </td>
                  <td>
                    <FaCheck />
                  </td>
                </tr>
                <tr className="price">
                  <td>Prix</td>
                  <td>100€/mois</td>
                  <td>400€/mois</td>
                  <td>300€/mois</td>
                </tr>
              </tbody>
            </table>
          </div>
        */}
        </div>

        <span style={{height: "1px", overflow:"hidden", display:"flex"}}>
        Consulter les formules et tarifs proposés.
        Contactez moi si vous voulez plus de renseignements sur les tarifs ou autres questions.
        Page de tarifs du <h1>centre équestre des écuries du Cha'let (chalet) à Annecy Epagny en Haute Savoie</h1>, très prês de la suisse et genève.
        Pour me contacter n'hésitez pas à me joindre sur le numéro de téléphone indiqué en bas de page.
        Balades à cheval, poney, manège et carrière olympique 20x40 m, des installations de qualité. 
        Cours de poney et de chevaux. Faites découvrir cette passion à votre enfant. Cheval pour enfant (chevaux pour enfants)
      </span>
      </Layout>
    );
  }
}
