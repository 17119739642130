import React from "react";
import { Checkbox } from "@material-ui/core";
import { Modal, Alert } from "react-bootstrap";
import * as styles from "../styles.module.scss";

export default class FooterPricing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      modalShow: false,
      prices: props.prices.map((price) => {
        return {
          ...price,
          checked: false,
        };
      }),
    };
  }

  togglePriceSelection = (price, forcedValue = null) => {
    const indexFound = this.state.prices.indexOf(price);
    if (indexFound > -1) {
      var statePrices = this.state.prices;
      var priceFound = statePrices[indexFound];
      priceFound.checked = forcedValue != null ? forcedValue : !priceFound.checked;
      statePrices[indexFound] = priceFound;

      this.setState({ prices: statePrices });
    }
  };

  getDefaultMessage = () => {
    var checkedPrices = this.state.prices.filter((price) => price.checked);

    if (checkedPrices) {
      var str = "Bonjour, j'aimerais m'inscrire au " + this.props.type + " :\n";
      checkedPrices.forEach((price) => {
        str += "- " + price.title + "\n";
      });
      str += "Merci et bonne journée :)";
      return str;
    } else {
      return "";
    }
  };

  handleButtonClick = (e) => {
    if (!this.state.prices.some((price) => price.checked)) {
      if (!this.state.error) {
        this.setState({ error: true });
        setTimeout(() => {
          this.setState({ error: false });
        }, 3000);
      }
      return;
    }

    if (this.props.showModal) this.props.showModal(this.getDefaultMessage());
  };

  render() {
    return (
      <>
        <div className={styles.panelPricing}>
          <div className={styles.panelMultiplePricing}>
            {this.state.prices.map((price) => {
              return (
                <div
                  key={price.title}
                  className={price.checked ? "active" : ""}
                  onClick={() => this.togglePriceSelection(price)}
                >
                  <Checkbox
                    color="primary"
                    checked={price.checked}
                    onChange={(e) => this.togglePriceSelection(price, e.target.checked)}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <div className={styles.priceContent}>
                    <div>
                      <div className={styles.title}>{price.title}</div>
                      {price.subtitle && <div className={styles.subTitle}>{price.subtitle}</div>}
                    </div>
                    <div>
                      <span className="price">{price.price}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.footer}>
          <button className="btn btn-lg btn-block btn-light mt-2" onClick={this.handleButtonClick}>
            FAIRE UNE DEMANDE DE RESERVATION
          </button>
          {this.state.error ? (
            <Alert variant="danger">Veuillez sélectionner une formule avant de réserver</Alert>
          ) : null}
        </div>
      </>
    );
  }
}
