// extracted by mini-css-extract-plugin
export var panel = "styles-module--panel--MqW2n";
export var panelHeading = "styles-module--panel-heading--dv60T";
export var listGroup = "styles-module--list-group--Tg4nh";
export var listGroupItem = "styles-module--list-group-item--ThIrl";
export var panelFooter = "styles-module--panel-footer--Rd8op";
export var btn = "styles-module--btn--mBmlP";
export var fa = "styles-module--fa--ugWfs";
export var footer = "styles-module--footer--FVhn3";
export var price = "styles-module--price--V07G5";
export var lead = "styles-module--lead--mXKP-";
export var panelPricing = "styles-module--panel-pricing--PyONA";
export var panelMultiplePricing = "styles-module--panel-multiple-pricing--Q-0jd";
export var active = "styles-module--active--Wix+B";
export var priceContent = "styles-module--price-content--vF+cP";
export var subTitle = "styles-module--sub-title--RBJDb";
export var panelGreen = "styles-module--panel-green--g7z24";
export var panelBody = "styles-module--panel-body--oAnKw";
export var panelRed = "styles-module--panel-red--NDlZN";
export var panelWhite = "styles-module--panel-white--L2uRO";
export var title = "styles-module--title--q92f7";