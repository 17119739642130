import React from 'react';
import * as styles from '../styles.module.scss'

export default class Footer extends React.Component {

    getDefaultMessage = () => {
        var str = "Bonjour, j'aimerais réserver une : " + this.props.type +".\n";
        str += "Merci et bonne journée !";
        return str;
    }

    render() {
        return (
            <div className={styles.footer}>
                <button className="btn btn-lg btn-block btn-light mt-2" onClick={() => this.props.showModal(this.getDefaultMessage())}>
                    FAIRE UNE DEMANDE DE RESERVATION 
                </button>
            </div>
        )
    }
}