import React, { Component } from 'react';
import * as styles from './styles.module.scss';
import { Title, Items, FooterPricing, Pricing, Footer } from './src';

class PricePanel extends Component {
    constructor(props) {
        super(props);
    }
  
    render() {
        return (
        <div className={`${styles.panel} ${styles.panelWhite}`}>
            {this.props.children}
        </div>
        );
    }
}

PricePanel.Title = Title;
PricePanel.Items = Items;
PricePanel.Pricing = Pricing;
PricePanel.FooterPricing = FooterPricing;
PricePanel.Footer = Footer;
export default PricePanel;