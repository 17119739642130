import React from 'react';
import * as styles from '../styles.module.scss'

export default class Pricing extends React.Component {
    render() {
        return (
            <div className={styles.panelPricing}>
                <span className={styles.price}>
                    {this.props.children}
                </span>
            </div>
        )
    }
}